import axios from 'axios';

export const api = async (url, method, body) => {
  let headers;

  headers = {
    'Content-Type': 'application/json',
  };

  let structure = {
    url: url,
    method: method,
    headers: headers,
    data: body,
  };

  return axios(structure)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      console.log('err:',err);
      console.log('err.response.data:',err.response.data);
      return err.response.data;
    });
};
