<template src="./main-slider.html"></template>
<script>
export default {
  name: "main-slider",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    let cardHeight = window.innerHeight - 200;
    if (cardHeight > 500) {
      cardHeight = 500;
    }
    let cardWidth = Math.round((cardHeight / 100) * 81.8);
    let sliderWidth = (cardWidth + 20) * 6;
    let r = document.querySelector(":root");
    r.style.setProperty("--card-height", cardHeight + "px");
    r.style.setProperty("--card-width", cardWidth + "px");
    r.style.setProperty("--slider-width", "-" + sliderWidth + "px");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./main-slider.css" scoped></style>
